$primary-color: #180d78;
$secondary-color: #e74c3c;
$blue: rgba(58, 148, 232, 0.8);
$pink: rgba(217, 65, 241, 0.8);
$light-blue-color: #0576b1;
$blue-color: #023E8A;
$button-color: #4ea4f0;
$button-light-color: rgb(89, 181, 217);
$light-gradient :linear-gradient(270deg,  #dc69e5, #77b8ea);
$dark-gradient : linear-gradient(140deg, rgb(22 38 54 / 99%) 5%, #17597c 90%);;
;
$border-radius: 15px;
