/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import "primeicons/primeicons.css";
@import './styles/_variables.scss';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Livvic, sans-serif;
}
.container{
  position: relative;
  max-width: 80%;
  margin: 0 auto;
}
p {
  text-indent: 1.2em
}

.p-dialog{
  box-shadow: none;
}
.p-dialog .p-dialog-content, .p-dialog-header  {
  padding: 20px;
  backdrop-filter: blur(15px);
  border-radius: 0 0 20px 20px;
}
.p-dialog-header{
  border-radius: 20px  20px 0 0;
}
.p-dialog-title {
  font-size: 2em
}
.p-confirm-popup  {
  display:flex;
  flex-direction: column;
  //height: 4em
}

.p-dialog-header-close-icon{
  color: white;
}
.p-confirm-popup-footer {
  margin: 15px 5px 15px 0;
  display: flex;
  gap: .3em;
  justify-content: flex-end;
  .p-confirm-popup-reject, .p-confirm-popup-accept{
    width: 3em;
    height: 2em;
    span{
      color: #0b4e89;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 1024px){
  .container{
    margin: 0 auto;
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px){
  .p-dialog-title{
    font-size: 18px
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 1.5rem;
    height: 1.5rem;
    svg {
      height: 80px;
      width: 15px;
      font-weight: bold;
    }
  }
}

