@import '../_variables.scss';

body.dark-theme {
  background: rgb(31, 65, 92);
  background-image: radial-gradient(circle at center center, rgb(31, 65, 92), rgb(12, 23, 33));
  color: #FFFFFF;

  .login button, .p-button, .join-btn button, .start-btn {
    background: $button-color;
    border: 1px solid transparent;
    color: #FFFFFF;
    transition-duration: 0.4s ;
  }

  .login:hover, .join-btn:hover  {
    transition-duration: 0.3s ;
    & button {
      background: transparent;
      border: 1px solid $button-color;
      box-shadow: 0 2px 5px 0 $button-color;
      color: $button-color;
      transition-duration: 0.3s ;
    }
  }


  .p-button:hover {
    background: transparent;
    border: 1px solid $button-color;
    box-shadow: 0 2px 5px 0 $button-color;
    color: $button-color;
  }

  a {
    color: #FFFFFF;
    text-decoration: none
  }

  .nav-item.focused {
    a {
      color: $button-color;
    }

    transform: scale(0.9);
    box-shadow: 0 8px 18px 0 rgba(132, 132, 213, 0.29);

  }

  .service-single-item-box, .single-work-progress-item, .join-section {
    background: rgb(31, 65, 92);
    background: linear-gradient(127deg, rgba(31, 65, 92, 1) 14%, rgba(12, 23, 33, 1) 34%, rgb(31, 65, 92) 60%, rgba(12, 23, 33, 1) 90%);
  }

  .service-title-link {
    color: #a0bcdf
  }

  .company-name, .eliminate-list-span {
    color: transparent;
    background: linear-gradient(140deg, rgb(225 232 239 / 80%) 5%, #0077b6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 900
  }

  .footer-top {
    background-color: #0b1624
  }

  .section-title, .work-progress-text {
    color: #FFFFFF;
  }

  .section-title {
    text-shadow: #0576b1 4px 3px 2px;
    span {
      text-shadow: #89c9ea 3px 2px 2px;
    }
  }

  .section-title-inner-style, .work-precess-number-text, .hero-title span, .section-company-name, .content__main h2,
  .service-title-link {
    color: $light-blue-color;
  }

  .hero-title {
    text-shadow: $light-blue-color 1px 0 10px;

  }

  .single-work-progress-item:hover .work-progress-text {
    color: $light-blue-color
  }

  .burger-menu .menu-span {
    background-color: $button-color;
  }

  .burger-menu .menu-last-span {
    background-color: $blue;
  }

  .social-media {
    ul li a .icon {
      color: rgba(255, 255, 255, 1);
    }
  }



  .p-dialog .p-dialog-content, .p-dialog-header {
    background: rgba(245, 242, 242, 0.3);
  }
  .p-dialog-header{
    border-radius: 12px 12px 0 0;
  }

  .p-dialog-title {
    color: #FFFFFF;
  }

  .snip1543:before,
  .snip1543:after {
    background-color: rgba(31, 65, 92, 1);
  }


  .snip1543 figcaption:before,
  .snip1543 figcaption:after {
    background-color: #295b83;
  }


  .header-right-block, .header-left-block {
    color: #7ca6d0 !important;

    a {
      color: #7ca6d0 !important;
      &:hover {
        color: #b1e3fa !important;
      }
    }
  }

  .header-top-link-separator, .header-horizontal-separator {
    background-color: #7ca6d0;
  }

  .logo {
    filter: drop-shadow(2px 3px 4px $blue-color);
  }

  .work-progress-horizontal-line-bottom {
    background-color: $light-blue-color
  }

}
