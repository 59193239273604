@import '../_variables.scss';

body.light-theme {
  background-image: linear-gradient(112.5deg, rgb(119, 170, 217) 0%, rgb(119, 170, 217) 10%, rgb(181, 241, 252) 10%, rgb(181, 241, 252) 53%, rgb(137, 194, 219) 53%, rgb(137, 194, 219) 55%, rgb(142, 195, 232) 55%, rgb(142, 195, 232) 60%, rgb(155, 176, 213) 60%, rgb(155, 176, 213) 88%, rgb(117, 149, 213) 88%, rgb(117, 149, 213) 91%, rgb(113, 120, 184) 91%, rgb(113, 120, 184) 100%), linear-gradient(157.5deg, rgb(119, 170, 217) 0%, rgb(119, 170, 217) 10%, rgb(181, 241, 252) 10%, rgb(181, 241, 252) 53%, rgb(137, 194, 219) 53%, rgb(137, 194, 219) 55%, rgb(142, 195, 232) 55%, rgb(142, 195, 232) 60%, rgb(155, 176, 213) 60%, rgb(155, 176, 213) 88%, rgb(117, 149, 213) 88%, rgb(117, 149, 213) 91%, rgb(113, 120, 184) 91%, rgb(113, 120, 184) 100%), linear-gradient(135deg, rgb(119, 170, 217) 0%, rgb(119, 170, 217) 10%, rgb(181, 241, 252) 10%, rgb(181, 241, 252) 53%, rgb(137, 194, 219) 53%, rgb(137, 194, 219) 55%, rgb(142, 195, 232) 55%, rgb(142, 195, 232) 60%, rgb(155, 176, 213) 60%, rgb(155, 176, 213) 88%, rgb(117, 149, 213) 88%, rgb(117, 149, 213) 91%, rgb(113, 120, 184) 91%, rgb(113, 120, 184) 100%), linear-gradient(90deg, rgb(181, 241, 252), rgb(148, 186, 211));
  background-blend-mode: overlay, overlay, overlay, normal;
  background-size: cover;
  background-repeat: no-repeat;

  a {
    text-decoration: none;
  }

  .header a {
    color: $blue-color;
  }

  .logo {
    filter: drop-shadow(2px 3px 6px $light-blue-color);
  }

  .nav-item.focused {
    a {
      color: $pink;
    }

    transform: scale(0.9);
    box-shadow: 0 8px 18px 0 rgba(41, 123, 212, 0.29);
  }

  .footer a {
    color: #FFFFFF;
    text-decoration: none;
  }


  .service-single-item-box, .join-section{
    background-color: #e7fbff;
    box-shadow: rgba(22, 48, 68, 0.56) 0 2px 5px, rgba(62, 101, 132, 0.56) 0 8px 15px, rgba(174, 211, 238, 0.56) 0 2px 56px;
  }

  .join-btn button, .login button, .start-btn, .p-button,  {
    border: 1px solid transparent;
    transition-duration: 0.3s ;
    background: $button-light-color;
    color:rgba(255, 255, 255, 0.9);
    a {
      color: rgba(255, 255, 255, 0.9);
    }
    &:hover {
      color: $light-blue-color;
      background: transparent;
      border: 1px solid $light-blue-color;
      a {
        color: $light-blue-color
      }
      transition-duration: 0.3s ;
    }
  }



  .company-name , .eliminate-list-span{
    color: transparent;
    background: linear-gradient(140deg, rgb(81 148 215 / 80%) 5%, rgb(172 19 181 / 80%) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 900
  }

  .footer-top {
    background-color: $blue-color
  }

  .contact-item a {
    color: #000
  }

  .section-title, .work-progress-text {
    color: $blue-color;
  }

  .section-title {
    text-shadow: $light-blue-color 3px 2px 2px;
    span {
      text-shadow: #89c9ea 3px 2px 2px;
    }
  }

  .section-title-inner-style, .work-precess-number-text,.section-company-name,  .hero-title span,
  .content__main h2{
    color: $light-blue-color
  }

  .section-text {
    color: #0c0c0c;
  }

  .hero-title {
    text-shadow: $light-blue-color 1px 0 2px;

  }

  .single-work-progress-item:hover .work-progress-text {
    color: $light-blue-color
  }

  .burger-menu .menu-span {
    background-color: $pink;
  }

  .burger-menu .menu-last-span {
    background-color: $blue;
  }

  .social-media {
    ul li a .icon {
      color: #023E8A;
    }
  }


  .content__right .content__main {
    color: #0c0c0c;
  }

  .content__right {
    background-color: rgb(140 220 245 / 50%);
    background: rgb(138,223,244);
    background: linear-gradient(127deg, rgba(208,239,247,0.5802696078431373) 14%, rgba(192,233,245,0.6951155462184874) 34%, rgba(80,188,245,0.5270483193277311) 60%, rgba(194,228,240,0.6054796918767507) 90%);
  }


  .p-dialog .p-dialog-content, .p-dialog-header {
    background: rgba(0, 0, 0, 0.2);
  }
  .p-dialog-header {
    border-radius: 12px 12px 0 0;
  }

  .p-dialog-title {
    color: #FFFFFF;
  }


  .snip1543:before,
  .snip1543:after {
    background-color: #4a9ee7;
  }


  .snip1543 figcaption:before,
  .snip1543 figcaption:after {
    background-color: #60bade;
  }

  .header-right-block,.header-left-block {
    color: #1c9feb !important;
    a{
      color: #1c9feb !important;
      &:hover {
        color: $light-blue-color !important;
      }
    }
  }

  .header-top-link-separator {
    background-color: #48cae4;
  }

  .header-horizontal-separator {
      background-color: #ade8f4;
  }


    .work-progress-horizontal-line-bottom {
      background-color: $blue-color
    }



//************************* Beige part
//  background-image: linear-gradient(225deg, rgba(242,233,228, 0.34) 0%, rgba(242,233,228, 0.34) 25%,rgba(189, 189, 189, 0.04) 25%, rgba(189, 189, 189, 0.04) 50%,rgba(106, 106, 106, 0.04) 50%, rgba(106, 106, 106, 0.04) 75%,rgba(201,173,167, 0.35) 75%, rgba(201,173,167, 0.35) 100%),linear-gradient(250deg, rgba(77, 77, 77, 0.04) 0%, rgba(77, 77, 77, 0.04) 25%,rgba(113, 113, 113, 0.04) 25%, rgba(113, 113, 113, 0.04) 50%,rgba(154,140,152, 0.21) 50%, rgba(154,140,152, 0.21) 75%,rgba(182, 182, 182, 0.04) 75%, rgba(182, 182, 182, 0.04) 100%),linear-gradient(209deg, rgba(169, 169, 169, 0.04) 0%, rgba(169, 169, 169, 0.04) 25%,rgba(164, 164, 164, 0.04) 25%, rgba(164, 164, 164, 0.04) 50%,rgba(242,233,228, 0.24) 50%, rgba(242,233,228, 0.24) 75%,rgba(201,173,167, 0.35) 75%, rgba(201,173,167, 0.35) 100%),linear-gradient(233deg, rgba(113, 113, 113, 0.04) 0%, rgba(113, 113, 113, 0.04) 25%,rgba(74,78,105, 0.31) 25%, rgba(74,78,105, 0.31) 50%,rgba(188, 188, 188, 0.04) 50%, rgba(188, 188, 188, 0.04) 75%,rgba(201,173,167, 0.5) 75%, rgba(201,173,167, 0.5) 100%),linear-gradient(210deg, rgba(57,57,57, 0) 0%, rgba(57,57,57, 0) 25%,rgba(227, 227, 227, 0.04) 25%, rgba(227, 227, 227, 0.04) 50%,rgba(154,140,152, 0.21) 50%, rgba(154,140,152, 0.21) 75%,rgba(26,26,26, 0.11) 75%, rgba(26,26,26, 0.11) 100%),linear-gradient(90deg, rgba(74,78,105, 0.31),rgba(34,34,59, 0.88));  background-size: cover;
//  background-repeat: no-repeat;
//
//  a {
//    text-decoration: none;
//  }
//
//  .header a {
//    color: #22223b;
//  }
//
//  .logo {
//    filter: drop-shadow(2px 3px 6px #f2e9e4);
//  }
//
//  .nav-item.focused {
//    a {
//      color: #f2e9e4;
//    }
//
//    transform: scale(0.9);
//    box-shadow: 0 8px 18px 0 rgb(201, 173, 167);
//  }
//
//  .footer a {
//    color: #FFFFFF;
//    text-decoration: none;
//  }
//
//
//  .service-single-item-box, .join-section{
//    background-color: #f2e9e4;
//    box-shadow: rgb(74, 78, 105) 0 2px 5px, rgba(154, 140, 152, 0.57) 0 8px 15px, rgba(174, 211, 238, 0.56) 0 2px 56px;
//  }
//
//  .join-btn button, .login button, .start-btn, .p-button,  {
//    border: 1px solid transparent;
//    transition-duration: 0.3s ;
//    background: #4a4e69;
//    color:rgba(255, 255, 255, 0.9);
//    a {
//      color: rgba(255, 255, 255, 0.9);
//    }
//    &:hover {
//      color: #22223b;
//      background: transparent;
//      border: 1px solid #22223b;
//      a {
//        color: #22223b
//      }
//      transition-duration: 0.3s ;
//    }
//  }
//
//
//
//  .company-name , .eliminate-list-span{
//    color: transparent;
//    background: #22223b;
//    background-clip: text;
//    -webkit-background-clip: text;
//    font-weight: 900
//  }
//
//  .footer-top {
//    background-color: #4a4e69
//  }
//
//  .contact-item a {
//    color: #000
//  }
//
//  .section-title, .work-progress-text {
//    color: #22223b;
//  }
//
//  .section-title {
//    text-shadow: #9a8c98 2px 2px 2px;
//  }
//
//  .section-title-inner-style, .work-precess-number-text,.section-company-name,  .hero-title span,
//  .content__main h2{
//    color: #c9ada7
//  }
//
//  .section-text {
//    color: #0c0c0c;
//  }
//
//  .hero-title {
//    text-shadow: $light-blue-color 1px 0 2px;
//
//  }
//
//  .single-work-progress-item:hover .work-progress-text, .service-title-link, .header-right-block, {
//    color: #22223b
//  }
//
//  .burger-menu .menu-span {
//    background-color: $pink;
//  }
//
//  .burger-menu .menu-last-span {
//    background-color: $blue;
//  }
//
//  .social-media {
//    ul li a .icon {
//      color: #023E8A;
//    }
//  }
//
//
//  .content__right .content__main {
//    color: #0c0c0c;
//  }
//
//  .content__right {
//    background-color: rgb(140 220 245 / 50%);
//    background: rgb(138,223,244);
//    background: linear-gradient(127deg, rgba(208,239,247,0.5802696078431373) 14%, rgba(192,233,245,0.6951155462184874) 34%, rgba(80,188,245,0.5270483193277311) 60%, rgba(194,228,240,0.6054796918767507) 90%);
//  }
//
//
//  .p-dialog .p-dialog-content, .p-dialog-header {
//    background: rgba(0, 0, 0, 0.2);
//  }
//  .p-dialog-header {
//    border-radius: 12px 12px 0 0;
//  }
//
//  .p-dialog-title {
//    color: #FFFFFF;
//  }
//
//
//  .snip1543:before,
//  .snip1543:after {
//    background-color: #4a9ee7;
//  }
//
//
//  .snip1543 figcaption:before,
//  .snip1543 figcaption:after {
//    background-color: #60bade;
//  }
//
//  .header-right-block,.header-left-block {
//    color: #22223b !important;
//    a{
//      color: #22223b !important;
//      &:hover {
//        color: #c9ada7 !important;
//      }
//    }
//  }
//
//  .header-top-link-separator {
//    background-color:  #22223b;
//  }
//
//  .header-horizontal-separator {
//    background-color: #22223b;
//  }
//
//  .single-work-progress-item:hover {
//    background: #f2e9e4;
//  }
//
//  .work-progress-horizontal-line-bottom {
//    background-color: #22223b
//  }
//
//  .footer-social-menu ul li a {
//    border: 1px solid #22223b !important;
//  }
//
//  .btn-color-mode-switch > label.btn-color-mode-switch-inner {
//
//    background: #c9ada7 !important;
//  }

}
